import { equal } from "@avvoka/shared"

/**
 * 
 * @param questions 
 * @param att 
 * @returns 
 */
export function isRepeatedAttribute (questions: Backend.Questionnaire.IQuestion[], att: string) {
  const question = getQuestionForAttribute(questions, att)

  if (question) {
    return isRepeatedQuestion(question)
  } else {
    return true
  }
}

/**
 * 
 * @param question 
 * @returns 
 */
export function isRepeatedQuestion (question: Backend.Questionnaire.IQuestion | undefined) {
  return !!question?.opts?.["repeater-id"]
}

/**
 * 
 * @param questions 
 * @param loopCounts 
 * @param att 
 * @returns 
 */
export function getLoopCountForAttribute (questions: Backend.Questionnaire.IQuestion[], loopCounts: Backend.Questionnaire.LoopCounts, att: string) {
  const question = getQuestionForAttribute(questions, att)
  if (!question) return

  const repeaterId = question.opts["repeater-id"]
  if (!repeaterId) return

  return loopCounts[repeaterId]
}

/**
 * Returns estimated loop count for an attribute, undefined if not within a repeater
 * @param questions 
 * @param param1 
 * @param att 
 */
export function getImplicitLoopCountForAttribute (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string): { repeaterId: string, loopCount: Backend.Questionnaire.Succession } | undefined {
  const question = getQuestionForAttribute(questions, att)
  if (!question) return

  const repeaterId = question.opts["repeater-id"]
  if (!repeaterId) return

  const entriesForAttribute = entries[att]
  if (entriesForAttribute && entriesForAttribute.length > 0) {
    return {
      repeaterId,
      loopCount: entriesForAttribute.reduce((memo, entry) => Math.max(entry.succession, memo), 0) + 1
    }
  }
}

/**
 * 
 * @param questions 
 * @param att 
 * @returns 
 */
export function getQuestionForAttribute (questions: Backend.Questionnaire.IQuestion[], att: string) {
  return questions.find((question) => question.att === att)
}

/**
 * 
 * @param questions 
 * @param entries 
 * @param att 
 * @param succession 
 * @returns 
 */
export function getEntry (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession): Backend.Questionnaire.Entry | undefined {
  const entrySuccession = isRepeatedAttribute(questions, att) ? succession : 0

  return entries[att]?.find((entry) => equal(entry.succession, entrySuccession))
}

/**
 * 
 * @param questions 
 * @param entries 
 * @param att 
 * @param succession 
 * @returns 
 */
export function getEntryOrDefault (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession): Backend.Questionnaire.Entry | undefined {
  const entry = getEntry(questions, entries, att, succession)
  if (entry) {
    return entry
  }

  const question = getQuestionForAttribute(questions, att)
  const entrySuccession = isRepeatedAttribute(questions, att) ? succession : 0

  if (question && (question.opts.default || question.opts.modificator)) {
    return {
      succession: entrySuccession,
      value: question.opts.default || '',
      modificator: question.opts.modificator || ''
    }
  }
}

/**
 * 
 * @param entries 
 * @param att 
 * @returns 
 */
export function hasEntryForAttribute (entries: Backend.Questionnaire.Entries, att: string) {
  return entries[att] ? entries[att].length > 0 : false
}

export function addEntryIdentifier (identifiers: Backend.Questionnaire.EntryIdentifier[], identifier: Backend.Questionnaire.EntryIdentifier) {
  const index = identifiers.findIndex((_identifier) => _identifier.att === identifier.att && equal(_identifier.succession, identifier.succession))
  if (index === -1) {
    identifiers.push({ ...identifier })
  }
}

export function removeEntryIdentifier (identifiers: Backend.Questionnaire.EntryIdentifier[], identifier: Backend.Questionnaire.EntryIdentifier) {
  const index = identifiers.findIndex((_identifier) => _identifier.att === identifier.att && equal(_identifier.succession, identifier.succession))
  if (index !== -1) {
    identifiers.splice(index, 1)
  }
}

/**
 * Sets entry
 * @param questions 
 * @param entries 
 * @param att 
 * @param entry 
 * @returns 
 */
export function setEntry (questions: Backend.Questionnaire.IQuestion[], entries: Backend.Questionnaire.Entries, att: string, entry: Backend.Questionnaire.Entry): { entry: Backend.Questionnaire.Entry, change: boolean } {
  entries[att] ||= []

  // Set succession to 0 if entry is not repeated
  const entrySuccession = isRepeatedAttribute(questions, att) ? entry.succession : 0
  const entryNormalized = { ...entry, succession: entrySuccession }

  // Check if entry exists in the entries array
  const entryIndex = entries[att].findIndex((localEntry) => equal(localEntry.succession, entrySuccession))
  if (entryIndex === -1) {
    // If entry doesnt exist, push it
    entries[att].push(entryNormalized)

    return {
      entry: entryNormalized,
      change: true
    }
  } else {
    const { value: existingValue, modificator: existingModificator } = entries[att][entryIndex]

    // Check if entry changed
    const entryEqual = equal(entry.value, existingValue) && entry.modificator == existingModificator
    if (entryEqual) {
      // If not, then return the original entry
      return {
        entry: entries[att][entryIndex],
        change: false
      }
    } else {
      return {
        entry: (entries[att][entryIndex] = entryNormalized),
        change: true
      }
    }
  }
}

export function unsetEntry (entries: Backend.Questionnaire.Entries, att: string, succession: Backend.Questionnaire.Succession) {
  const entryIndex = entries[att]?.findIndex((localEntry) => equal(localEntry.succession, succession)) ?? -1
  if (entryIndex !== -1) {
    entries[att].splice(entryIndex)

    if (entries[att].length === 0) {
      delete entries[att]
    }

    return true
  } else {
    return false
  }
}

/**
 * Splits value by delimiter
 * @param value Value
 * @param delimiter Delimiter
 * @returns Array of segments, or empty array if value is blank
 */
export function splitEntryValue (value: string | undefined, delimiter: string) {
  return value ? value.split(delimiter) : []
}